import React from "react"
import Section from "./section"
import { StaticQuery, graphql } from "gatsby"

const Products = (props) => (
  <StaticQuery 
    query={graphql`
    query ProductQuery {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              abstract
              benefits{
                icon
                iconTitle
                desc
              }
              category
              contraindications
              image
              indications
              ingredients
              name
              packages
              title
              type
            }
          }
        }
      }
    }
    
    
    `}
    render={(data) =>(

          (data.allMarkdownRemark.edges).map( ({node}) => {
            if (node.frontmatter.type ===props.animal) return <Section key = {node.id} product= {node} />
            

          }
          
          )
        
)}
/>

)

export default Products