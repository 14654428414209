import React from "react"
import Intro from "../components/intro"
import Products from "../components/products"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Anasayfa" />
    <Intro />
    <div id="content">
        <div className="sections py-6 py-md-8 py-lg-10" id="dogs">
          <div className="container text-center">
            <h2 className="h1 headline headline--dog text-center">KÖPEK</h2>
          </div>
            <Products animal="dog"/>
          </div>
          <div className="sections py-6 py-md-8 py-lg-10" id="cats">
          <div className="container text-center">
            <h2 className="h1 headline headline--cat text-center">KEDİ</h2>
          </div>
            <Products animal="cat"/>
          </div>
      </div>
    
  </Layout>
)

export default IndexPage
