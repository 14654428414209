import React from 'react'
import { Teaser, Indications, Package, BenefitsStatic, Caption } from './section_part'

const Section = function(props) { 
    
    const product=props.product
    
    
    return (
    (
    <section className={"section section--"+product.frontmatter.category+" section--"+product.frontmatter.type+"s"}>
      <Caption data={product.frontmatter}/>
      <div className="section__content">
            <div className="container">
                <div className="row align-items-start mb-2">
                    <div className="col-lg-7 mr-auto">
                      <Teaser data={product.frontmatter}/>
                      <Indications data={product.frontmatter}/>
                    </div>
                    
                     <Package data={product.frontmatter}/>
                    

                </div>
                <BenefitsStatic data={product.frontmatter}/>
            </div>
      </div>
    

    </section>
    )


)}

export default Section