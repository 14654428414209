import { Link } from "gatsby"
import React from "react"


const Intro = ()=>(
  <div className="intro">
    <div className="container">
        <div className="intro__content text-center text-md-left">
          <div className="row">
            <div className="col-12 col-lg-6">
            <h1 className="h2 mb-2"><span className="font-weight-normal">Tahılsız</span><br/> Veterİner Dİyet</h1>
            <p className="perex mb-2">Brit kalitesinden ödün vermeden en mükemmel içerikler ile hazırlamış olduğu veteriner diyet mama serisini sunar. </p>
            <div className="-mx-1">
                <Link to="/#dogs" className="btn btn--gradient-primary btn--lg btn--dog m-1">Köpek</Link>
                <Link to="/#cats" className="btn btn--gradient-primary btn--lg btn--cat m-1">Kedi </Link>
            </div>
              
            </div>
          </div>
        </div>
    </div>
    <div className="pointer">
            <div className="container">
                <Link to="/#content" title="İçeriğe Git">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="28" height="32">
                        <path fill="currentColor" d="M207 381L13 187c-10-9-10-24 0-34l22-22c10-10 25-10 34-1l155 155 155-155c9-9 24-9 34 1l22 22c10 10 10 25 0 34L241 381c-9 10-25 10-34 0z"/>
                    </svg>
                </Link>
            </div>
        </div>
  
  </div>


)

export default Intro